import React, { useState, useEffect, useRef, useCallback } from 'react';
import { gsap } from 'gsap';
import './css/Window.css';
import sendIcon from './assets/icon-send.png';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

function Window() {
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [userId, setUserId] = useState(
    localStorage.getItem('userId') || uuidv4()
  );
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem('userId')) {
      localStorage.setItem('userId', userId);
    }
  }, [userId]);

  useEffect(() => {
    // Загрузка истории сообщений при первом рендере компонента
    const fetchMessages = async () => {
      try {
        const response = await axios.post('https://api.clever-coin.ru/chat/history', {
          id: userId,
        });

        console.log('Loaded messages:', response.data.messages);

        if (response.data && Array.isArray(response.data.messages)) {
          const loadedMessages = response.data.messages.map((message) => ({
            role: message.role,
            content: message.content,
          }));
          setMessages(loadedMessages.reverse()); // Обратный порядок для отображения последних сообщений снизу
        } else {
          console.error('No messages found or incorrect response format.');
          setMessages([]);
        }
      } catch (error) {
        console.error('Error fetching messages from server:', error);
        alert('Failed to load messages from the server. Please try again later.');
      }
    };

    fetchMessages();
  }, [userId]);

  const toggleWindow = useCallback(() => {
    setIsVisible((prevState) => !prevState);
  }, []);

  const handleScroll = useCallback(() => {
    if (buttonRef.current) {
      const top = buttonRef.current.getBoundingClientRect().top;
      // Дополнительная логика для обработки прокрутки, если потребуется
    }
  }, []);

  const handleResize = useCallback(() => {
    if (window.innerWidth < 500 && isVisible) {
      gsap.to(containerRef.current, {
        y: Math.min(
          0,
          window.innerHeight - 50 - containerRef.current.offsetHeight
        ),
        duration: 0.3,
      });
    } else if (isVisible) {
      gsap.to(containerRef.current, { y: 0, duration: 0.3 });
    } else {
      gsap.to(containerRef.current, { y: '91%', duration: 0.3 });
    }
    handleScroll();
  }, [isVisible, handleScroll]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleResize, handleScroll]);

  useEffect(() => {
    gsap.to(containerRef.current, { y: isVisible ? 0 : '91%', duration: 0.3 });
  }, [isVisible]);

  const handleSendMessage = async () => {
    if (inputText.trim()) {
      const newMessage = { role: 'user', content: inputText };
      setMessages((prevMessages) => [newMessage, ...prevMessages]);
      setInputText('');

      if (inputRef.current) {
        inputRef.current.focus();
      }

      if (contentRef.current) {
        setTimeout(() => {
          contentRef.current.scrollTop = 0;
        }, 0);
      }

      console.log('Sending message:', inputText);
      console.log('User ID:', userId);

      try {
        const response = await axios.post('https://api.clever-coin.ru/chat', {
          id: userId,
          message: inputText,
        });

        console.log('Response from server:', response.data);

        if (response.data && response.data.response) {
          const botResponse = {
            role: 'assistant',
            content: response.data.response,
          };
          setMessages((prevMessages) => [botResponse, ...prevMessages]);
        } else {
          console.error('No response from server or incorrect response format.');
        }
      } catch (error) {
        console.error('Error sending message to server:', error);
        alert('Failed to send message to the server. Please try again later.');
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="container" ref={containerRef}>
      <div className="window">
        <div className="window-content" ref={contentRef}>
          {messages.length > 0 ? (
            messages.map((message, index) => (
              <div key={index} className={`chat-message ${message.role}`}>
                {message.content}
              </div>
            ))
          ) : (
            <div className="no-messages">Нет сообщений</div>
          )}
        </div>
        <div className="input-container">
          <textarea
            className="window-input"
            rows="3"
            placeholder="Введите сообщение..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={inputRef}
          ></textarea>
          <img
            src={sendIcon}
            alt="Send"
            className="send-icon"
            onClick={handleSendMessage}
          />
        </div>
      </div>
      <button className="toggle-button" ref={buttonRef} onClick={toggleWindow}>
        {isVisible ? 'Close ChatGPT' : 'Open ChatGPT'}
      </button>
    </div>
  );
}

export default Window;
